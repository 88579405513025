import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import Dashboard from 'layout/Dashboard';
import ProtectedRoute from './ProtectedRoute';

// Lazy load components
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/index')));
const Color = Loadable(lazy(() => import('pages/component-overview/color')));
const Typography = Loadable(lazy(() => import('pages/component-overview/typography')));
const Shadow = Loadable(lazy(() => import('pages/component-overview/shadows')));
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')));
const Sessions = Loadable(lazy(() => import('pages/extra-pages/Sessions')));
const Upload = Loadable(lazy(() => import('pages/extra-pages/Upload')));
const Todo = Loadable(lazy(() => import('pages/extra-pages/Todo')));
const Notes = Loadable(lazy(() => import('pages/extra-pages/Notes')));
const Tags = Loadable(lazy(() => import('pages/extra-pages/Tags')));
const Analytics = Loadable(lazy(() => import('pages/extra-pages/Analytics')));
const MyPreferences = Loadable(lazy(() => import('pages/extra-pages/MyPreferences')));
const Settings = Loadable(lazy(() => import('pages/extra-pages/Settings')));
const SessionDetails = Loadable(lazy(() => import('pages/extra-pages/SessionDetails')));
const MyProfile = Loadable(lazy(() => import('pages/extra-pages/MyProfile')));
const Billing = Loadable(lazy(() => import('pages/extra-pages/Billing')));
const PrivacyCenter = Loadable(lazy(() => import('pages/extra-pages/PrivacyCenter')));
const Feedback = Loadable(lazy(() => import('pages/extra-pages/Feedback')));
const AuthLogin = Loadable(lazy(() => import('pages/authentication/login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/register')));
const VerifyEmail = Loadable(lazy(() => import('pages/authentication/verify-email')));
const NotFound = Loadable(lazy(() => import('pages/NotFound')));

const MainRoutes = {
  path: '/',
  element: <Dashboard />,
  children: [
    {
      path: '/',
      element: <Navigate to="/dashboard" replace />
    },
    {
      path: 'dashboard',
      element: (
        <ProtectedRoute>
          <DashboardDefault />
        </ProtectedRoute>
      )
    },
    {
      path: 'color',
      element: (
        <ProtectedRoute>
          <Color />
        </ProtectedRoute>
      )
    },
    {
      path: 'typography',
      element: (
        <ProtectedRoute>
          <Typography />
        </ProtectedRoute>
      )
    },
    {
      path: 'shadow',
      element: (
        <ProtectedRoute>
          <Shadow />
        </ProtectedRoute>
      )
    },
    {
      path: 'sample-page',
      element: (
        <ProtectedRoute>
          <SamplePage />
        </ProtectedRoute>
      )
    },
    {
      path: 'sessions',
      element: (
        <ProtectedRoute>
          <Sessions />
        </ProtectedRoute>
      )
    },
    {
      path: 'upload',
      element: (
        <ProtectedRoute>
          <Upload />
        </ProtectedRoute>
      )
    },
    {
      path: 'todo',
      element: (
        <ProtectedRoute>
          <Todo />
        </ProtectedRoute>
      )
    },
    {
      path: 'notes',
      element: (
        <ProtectedRoute>
          <Notes />
        </ProtectedRoute>
      )
    },
    {
      path: 'tags',
      element: (
        <ProtectedRoute>
          <Tags />
        </ProtectedRoute>
      )
    },
    {
      path: 'analytics',
      element: (
        <ProtectedRoute>
          <Analytics />
        </ProtectedRoute>
      )
    },
    {
      path: 'preferences',
      element: (
        <ProtectedRoute>
          <MyPreferences />
        </ProtectedRoute>
      )
    },
    {
      path: 'settings',
      element: (
        <ProtectedRoute>
          <Settings />
        </ProtectedRoute>
      )
    },
    {
      path: 'sessions/:id',
      element: (
        <ProtectedRoute>
          <SessionDetails />
        </ProtectedRoute>
      )
    },
    {
      path: 'my-profile',
      element: (
        <ProtectedRoute>
          <MyProfile />
        </ProtectedRoute>
      )
    },
    {
      path: 'billing',
      element: (
        <ProtectedRoute>
          <Billing />
        </ProtectedRoute>
      )
    },
    {
      path: 'privacy-center',
      element: (
        <ProtectedRoute>
          <PrivacyCenter />
        </ProtectedRoute>
      )
    },
    {
      path: 'feedback',
      element: (
        <ProtectedRoute>
          <Feedback />
        </ProtectedRoute>
      )
    }
  ]
};

const AuthRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: 'register',
      element: <AuthRegister />
    },
    {
      path: 'verify-email',
      element: <VerifyEmail />
    }
  ]
};

const Routes = [
  MainRoutes,
  AuthRoutes,
  {
    path: '*',
    element: <NotFound />
  }
];

export default Routes;
