import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { PreferencesProvider } from 'contexts/PreferencesContext';

// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';

export default function App() {
  return (
    <Router>
      <PreferencesProvider>
        <ThemeCustomization>
          <ScrollTop>
            <Routes />
          </ScrollTop>
        </ThemeCustomization>
      </PreferencesProvider>
    </Router>
  );
}
