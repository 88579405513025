import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { usePreferences } from '../contexts/PreferencesContext';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = usePreferences();
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired
};

export default ProtectedRoute;
