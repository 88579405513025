// material-ui
import { useTheme } from '@mui/material/styles';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const theme = useTheme();

  return (
    <svg width="40" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* Road */}
      <path d="M10 90 C30 70, 70 70, 90 50" stroke={theme.palette.primary.dark} strokeWidth="4" fill="none" />
      <path d="M50 100 C70 80, 100 50, 100 10" stroke={theme.palette.primary.main} strokeWidth="4" fill="none" />

      {/* Landscape - Sun and Mountain */}
      <circle cx="30" cy="30" r="10" fill={theme.palette.secondary.main} />
      <path d="M20 80 L50 40 L80 80 Z" fill={theme.palette.primary.dark} fillOpacity="0.7" />

      {/* Lines for additional design elements */}
      <line x1="10" y1="10" x2="90" y2="90" stroke={theme.palette.primary.main} strokeWidth="2" />
      <line x1="90" y1="10" x2="10" y2="90" stroke={theme.palette.primary.main} strokeWidth="2" />
    </svg>
  );
};

export default Logo;
