import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { supabase } from 'src/supabaseClient';

const PreferencesContext = createContext();

export function PreferencesProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [taskers, setTaskers] = useState([]);
  const [methodologies, setMethodologies] = useState([]);
  const [sessionTypes, setSessionTypes] = useState([]);
  const [selfAssessments, setSelfAssessments] = useState([]);

  const saveUserMetadata = async (userId, metadata) => {
    const { error } = await supabase.from('user_metadata').insert([{ user_id: userId, ...metadata }]);

    if (error) {
      console.error('Error saving user metadata:', error);
      throw error;
    }
  };

  const register = async ({ email, password, options }) => {
    setLoading(true);
    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            first_name: options.data.first_name,
            last_name: options.data.last_name,
            title: options.data.title,
            gender: options.data.gender
          }
        }
      });
      if (error) throw error;

      try {
        await saveUserMetadata(data.user.id, {
          title: options.data.title,
          gender: options.data.gender
        });
      } catch (metadataError) {
        console.error('Metadata save error:', metadataError);
        // Handle metadata save error, but don't stop registration flow
      }

      setUser(data.user);
      setIsAuthenticated(true); // Optional: Explicitly set authentication state
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      const {
        data: { user }
      } = await supabase.auth.getUser();
      setIsAuthenticated(!!user);
      setUser(user);
      setLoading(false);
      if (user) fetchPreferences(user.id);
    };

    checkAuth();

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setIsAuthenticated(!!session);
      setUser(session?.user || null);
      setLoading(false);
      if (session?.user) fetchPreferences(session.user.id);
    });

    return () => {
      if (authListener && authListener.unsubscribe) {
        authListener.unsubscribe();
      }
    };
  }, []);

  const fetchPreferences = async (userId) => {
    const [taskersResponse, methodologiesResponse, sessionTypesResponse, selfAssessmentsResponse] = await Promise.all([
      supabase.from('taskers').select('*').eq('user_id', userId),
      supabase.from('methodologies').select('*').eq('user_id', userId),
      supabase.from('session_types').select('*').eq('user_id', userId),
      supabase.from('self_assessments').select('*').eq('user_id', userId)
    ]);

    if (taskersResponse.error) console.error('Error fetching taskers:', taskersResponse.error);
    if (methodologiesResponse.error) console.error('Error fetching methodologies:', methodologiesResponse.error);
    if (sessionTypesResponse.error) console.error('Error fetching session types:', sessionTypesResponse.error);
    if (selfAssessmentsResponse.error) console.error('Error fetching self-assessments:', selfAssessmentsResponse.error);

    setTaskers(taskersResponse.data || []);
    setMethodologies(methodologiesResponse.data || []);
    setSessionTypes(sessionTypesResponse.data || []);
    setSelfAssessments(selfAssessmentsResponse.data || []);
  };

  const login = async (email, password) => {
    setLoading(true);
    const { error } = await supabase.auth.signInWithPassword({ email, password });
    setLoading(false);
    if (error) throw error;
  };

  const logout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
  };

  const addTasker = async (taskerName) => {
    const {
      data: { user }
    } = await supabase.auth.getUser();
    const { data, error } = await supabase
      .from('taskers')
      .insert([{ name: taskerName, user_id: user.id }])
      .select();

    if (error) {
      console.error('Error adding tasker:', error);
    } else {
      setTaskers([...taskers, data[0]]);
    }
  };

  const updateTasker = async (id, newName) => {
    const { error } = await supabase.from('taskers').update({ name: newName }).eq('id', id);

    if (error) {
      console.error('Error updating tasker:', error);
    } else {
      setTaskers(taskers.map((tasker) => (tasker.id === id ? { ...tasker, name: newName } : tasker)));
    }
  };

  const deleteTasker = async (id) => {
    const { error } = await supabase.from('taskers').delete().eq('id', id);

    if (error) {
      console.error('Error deleting tasker:', error);
    } else {
      setTaskers(taskers.filter((tasker) => tasker.id !== id));
    }
  };

  const addMethodology = async (methodologyName) => {
    const {
      data: { user }
    } = await supabase.auth.getUser();
    const { data, error } = await supabase
      .from('methodologies')
      .insert([{ name: methodologyName, user_id: user.id }])
      .select();

    if (error) {
      console.error('Error adding methodology:', error);
    } else {
      setMethodologies([...methodologies, data[0]]);
    }
  };

  const updateMethodology = async (id, newName) => {
    const { error } = await supabase.from('methodologies').update({ name: newName }).eq('id', id);

    if (error) {
      console.error('Error updating methodology:', error);
    } else {
      setMethodologies(methodologies.map((methodology) => (methodology.id === id ? { ...methodology, name: newName } : methodology)));
    }
  };

  const deleteMethodology = async (id) => {
    const { error } = await supabase.from('methodologies').delete().eq('id', id);

    if (error) {
      console.error('Error deleting methodology:', error);
    } else {
      setMethodologies(methodologies.filter((methodology) => methodology.id !== id));
    }
  };

  const addSessionType = async (sessionTypeName) => {
    const {
      data: { user }
    } = await supabase.auth.getUser();
    const { data, error } = await supabase
      .from('session_types')
      .insert([{ name: sessionTypeName, user_id: user.id }])
      .select();

    if (error) {
      console.error('Error adding session type:', error);
    } else {
      setSessionTypes([...sessionTypes, data[0]]);
    }
  };

  const updateSessionType = async (id, newName) => {
    const { error } = await supabase.from('session_types').update({ name: newName }).eq('id', id);

    if (error) {
      console.error('Error updating session type:', error);
    } else {
      setSessionTypes(sessionTypes.map((sessionType) => (sessionType.id === id ? { ...sessionType, name: newName } : sessionType)));
    }
  };

  const deleteSessionType = async (id) => {
    const { error } = await supabase.from('session_types').delete().eq('id', id);

    if (error) {
      console.error('Error deleting session type:', error);
    } else {
      setSessionTypes(sessionTypes.filter((sessionType) => sessionType.id !== id));
    }
  };

  const addSelfAssessment = async (assessmentName) => {
    const {
      data: { user }
    } = await supabase.auth.getUser();
    const { data, error } = await supabase
      .from('self_assessments')
      .insert([{ name: assessmentName, user_id: user.id }])
      .select();

    if (error) {
      console.error('Error adding self-assessment:', error);
    } else {
      setSelfAssessments([...selfAssessments, data[0]]);
    }
  };

  const updateSelfAssessment = async (id, newName) => {
    const { error } = await supabase.from('self_assessments').update({ name: newName }).eq('id', id);

    if (error) {
      console.error('Error updating self-assessment:', error);
    } else {
      setSelfAssessments(selfAssessments.map((assessment) => (assessment.id === id ? { ...assessment, name: newName } : assessment)));
    }
  };

  const deleteSelfAssessment = async (id) => {
    const { error } = await supabase.from('self_assessments').delete().eq('id', id);

    if (error) {
      console.error('Error deleting self-assessment:', error);
    } else {
      setSelfAssessments(selfAssessments.filter((assessment) => assessment.id !== id));
    }
  };

  return (
    <PreferencesContext.Provider
      value={{
        isAuthenticated,
        loading,
        user,
        login,
        logout,
        register,
        taskers,
        addTasker,
        updateTasker,
        deleteTasker,
        methodologies,
        addMethodology,
        updateMethodology,
        deleteMethodology,
        sessionTypes,
        addSessionType,
        updateSessionType,
        deleteSessionType,
        selfAssessments,
        addSelfAssessment,
        updateSelfAssessment,
        deleteSelfAssessment
      }}
    >
      {children}
    </PreferencesContext.Provider>
  );
}

PreferencesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export function usePreferences() {
  return useContext(PreferencesContext);
}
