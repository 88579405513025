// assets
import {
  DashboardOutlined,
  HomeOutlined,
  FileTextOutlined,
  CloudUploadOutlined,
  CheckCircleOutlined,
  BookOutlined,
  TagOutlined,
  BarChartOutlined,
  SettingOutlined,
  ToolOutlined
} from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  HomeOutlined,
  FileTextOutlined,
  CloudUploadOutlined,
  CheckCircleOutlined,
  BookOutlined,
  TagOutlined,
  BarChartOutlined,
  SettingOutlined,
  ToolOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'home',
      title: 'Home',
      type: 'item',
      url: '/',
      icon: icons.HomeOutlined,
      breadcrumbs: false
    },
    {
      id: 'my-sessions',
      title: 'My Sessions',
      type: 'item',
      url: '/sessions',
      icon: icons.FileTextOutlined,
      breadcrumbs: false
    },
    {
      id: 'upload',
      title: 'Upload',
      type: 'item',
      url: '/upload',
      icon: icons.CloudUploadOutlined,
      breadcrumbs: false
    },
    {
      id: 'todo',
      title: 'To-Do',
      type: 'item',
      url: '/todo',
      icon: icons.CheckCircleOutlined,
      breadcrumbs: false
    },
    {
      id: 'notes',
      title: 'Notes',
      type: 'item',
      url: '/notes',
      icon: icons.BookOutlined,
      breadcrumbs: false
    },
    {
      id: 'tags',
      title: 'Tags',
      type: 'item',
      url: '/tags',
      icon: icons.TagOutlined,
      breadcrumbs: false
    },
    {
      id: 'analytics',
      title: 'Analytics',
      type: 'item',
      url: '/analytics',
      icon: icons.BarChartOutlined,
      breadcrumbs: false
    },
    {
      id: 'preferences',
      title: 'My Preferences',
      type: 'item',
      url: '/preferences',
      icon: icons.ToolOutlined,
      breadcrumbs: false
    },
    {
      id: 'settings',
      title: 'Settings',
      type: 'item',
      url: '/settings',
      icon: icons.SettingOutlined,
      breadcrumbs: false
    }
  ]
};

export default dashboard;
